<template lang="">
    <div>
        <v-row><v-col cols="12"></v-col></v-row>
        
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                        <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                            SEARCH FILTER
                        </div>
                    </v-card-title>

                    <v-row class="pa-4">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2" v-if="brokerMap.length > 1">
                            <v-autocomplete label="Filter Broker"
                                v-model="selectedBrokerId" :items="brokerMap" item-text="broker" item-value="broker_id"
                                auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete label="Filter Book"
                                v-model="selectedBook" :items="filterBookLists" item-text="book" item-value="book"
                                auto-select-first multiple chips deletable-chips small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <v-autocomplete label="Filter Transaction Type"
                                v-model="selectedTransactionType" :items="filterTransactionType" item-text="name"
                                item-value="value" auto-select-first chips multiple deletable-chips small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <div class="text-left" style="margin-left:20px;margin-top:20px">
                                <div>
                                    <v-btn text icon color="blue lighten-2" @click="showMore()" v-if="!showMoreStatus">
                                        More
                                        <v-icon large>
                                            mdi-chevron-down
                                        </v-icon>
                                    </v-btn>
                                    <v-btn text icon color="blue lighten-2" @click="showMore()" v-if="showMoreStatus">
                                        Fewer
                                        <v-icon large>
                                            mdi-chevron-up
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pa-4" v-if="showMoreStatus">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-text-field label="Login (Seprate by Comma)" v-model="selectedLogin" hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-datetime-picker label="Start Date" v-model="selectedStartDate"></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-datetime-picker label="End Date" v-model="selectedEndDate"></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete label="Order By"
                                v-model="selectedOrderBy" :items="filterOrderBy"
                                auto-select-first chips clearable deletable-chips  small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-switch style="width: 100%;" v-model="isAsc" :label="`Is Ascending Order?`"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete label="Show Result"
                                v-model="selectedLimit" :items="filterShowResult"
                                auto-select-first chips clearable deletable-chips  small-chips></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="pa-4">
                        <v-col cols="4">
                            <v-btn v-on:click="fetchTransactionRecord()" :loading="btnLoading" color="primary">SEARCH</v-btn>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                        <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                            RESULT
                        </div>
                        <div class="text-right" style="margin-right: 0; width: 50%;">
                            <download-excel :data="tradesList" :name="'Search'">
                                <v-icon color="green" dark dense>
                                    mdi-microsoft-excel
                                </v-icon>
                            </download-excel>
                        </div>
                    </v-card-title>

                    <v-row>
                        <v-col cols="12">
                            <v-card flat>
                                <!-- <DataTable :headers="headers" :dataSource="tradesList" :search="search"/> -->
                                <v-data-table dense :search="search" :headers="headers" :loading="tableLoading"
                                    :items="filteredDataSource" class="elevation-1" :items-per-page="15"
                                    @update:sort-by="tableEventSortBy"
                                    @update:sort-desc="tableEventSortDesc"
                                    >
                                    <template v-slot:header.login="{ header }">
                                        {{ header.text }}
                                        <v-menu offset-y :close-on-content-click="false" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`loginName`)">
                                                    <v-icon small :color="loginName ? 'primary' : ''">
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div style="background-color: #1e1e1e; width: 280px; ">
                                                <v-text-field v-model="loginName" class="px-4" type="text" ref="loginName"
                                                    label="Enter the search term" style="width: 100%;"></v-text-field>
                                                <v-btn @click="loginName = ''" small text color="primary" class="ml-2 mb-2">
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.book="{ header }">
                                        {{ header.text }}
                                        <v-menu offset-y :close-on-content-click="false" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`bookName`)">
                                                    <v-icon small :color="bookName ? 'primary' : ''">
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div style="background-color: #1e1e1e; width: 280px; ">
                                                <v-text-field v-model="bookName" class="px-4" type="text" ref="bookName"
                                                    label="Enter the search term" style="width: 100%;"></v-text-field>
                                                <v-btn @click="bookName = ''" small text color="primary" class="ml-2 mb-2">
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.deposit="{ header }">
                                        {{ header.text }}
                                        <v-menu offset-y :close-on-content-click="false" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`depositName`)">
                                                    <v-icon small :color="depositName ? 'primary' : ''">
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div style="background-color: #1e1e1e; width: 280px; ">
                                                <v-text-field v-model="depositName" class="px-4" type="text" ref="depositName"
                                                    label="Deposit >=" style="width: 100%;"></v-text-field>
                                                <v-btn @click="depositName = ''" small text color="primary" class="ml-2 mb-2">
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.task_deposit="{ header }">
                                        {{ header.text }}
                                        <v-menu offset-y :close-on-content-click="false" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`taskDepositName`)">
                                                    <v-icon small :color="taskDepositName ? 'primary' : ''">
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div style="background-color: #1e1e1e; width: 280px; ">
                                                <v-text-field v-model="taskDepositName" class="px-4" type="text" ref="taskDepositName"
                                                    label="Task Deposit >=" style="width: 100%;"></v-text-field>
                                                <v-btn @click="taskDepositName = ''" small text color="primary" class="ml-2 mb-2">
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.withdraw="{ header }">
                                        {{ header.text }}
                                        <v-menu offset-y :close-on-content-click="false" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`withdrawName`)">
                                                    <v-icon small :color="withdrawName ? 'primary' : ''">
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div style="background-color: #1e1e1e; width: 280px; ">
                                                <v-text-field v-model="withdrawName" class="px-4" type="text" ref="withdrawName"
                                                    label="Withdraw <=" style="width: 100%;"></v-text-field>
                                                <v-btn @click="withdrawName = ''" small text color="primary" class="ml-2 mb-2">
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.task_withdraw="{ header }">
                                        {{ header.text }}
                                        <v-menu offset-y :close-on-content-click="false" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`taskWithdrawName`)">
                                                    <v-icon small :color="taskWithdrawName ? 'primary' : ''">
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div style="background-color: #1e1e1e; width: 280px; ">
                                                <v-text-field v-model="taskWithdrawName" class="px-4" type="text" ref="taskWithdrawName"
                                                    label="Task Withdraw <=" style="width: 100%;"></v-text-field>
                                                <v-btn @click="taskWithdrawName = ''" small text color="primary" class="ml-2 mb-2">
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.user_group="{ header }">
                                        {{ header.text }}
                                        <v-menu offset-y :close-on-content-click="false" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`groupName`)">
                                                    <v-icon small :color="groupName ? 'primary' : ''">
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div style="background-color: #1e1e1e; width: 280px; ">
                                                <v-text-field v-model="groupName" class="px-4" type="text" ref="groupName"
                                                    label="Enter the search term" style="width: 100%;"></v-text-field>
                                                <v-btn @click="groupName = ''" small text color="primary" class="ml-2 mb-2">
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>

    </div>
</template>
<script>
import * as apiBook from "@components/api/pk/book";
import * as apiSearchTransaction from "@components/api/pk/search-transaction";
import DataTable from "@components/search/DataTable";
import { mapState } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";

export default {
    components: { DataTable },
    mixins: [snackbar, permission, helper],
    data () {
        return {
            showMoreStatus: false,
            loginName: "",
            bookName: "",
            depositName: "",
            taskDepositName: "",
            withdrawName: "",
            taskWithdrawName: "",
            groupName: "",
            selectedTransactionType: [
                "deposit",
                "withdraw",
                "task_deposit",
                "task_withdraw"
            ],
            selectedBrokerId: 1,
            selectedBook: [],
            selectedOffsetStart: 0,
            selectedLimit: 100,
            selectedLogin: "",
            selectedOrderBy: "login",
            selectedStartDate: null,
            selectedEndDate: null,

            btnLoading: false,
            tableLoading: false,
            isAsc: false,
            filterShowResult: [100, 200, 400, 600, 1000, 2000],
            filterOrderBy: [
                "login",
                "book",
                "deposit",
                "task_deposit",
                "withdraw",
                "task_withdraw"
            ],
            filterTransactionType: [
                { name: "Deposit", value: "deposit" },
                { name: "Withdraw", value: "withdraw" },
                { name: "Task Deposit", value: "task_deposit" },
                { name: "Task Withdraw", value: "task_withdraw" }
            ],
            filterBookLists: [],

            tradesList: [],
            csvHeader: {
                login: "Login",
                book: "Book",
                amount: "Amount",
                user_group: "Group"
            },

            search: "",
            headers: [
                { text: "Login", value: "login" },
                { text: "Book", value: "book" },
                { text: "Deposit", value: "deposit" },
                { text: "Task Deposit", value: "task_deposit" },
                { text: "Withdraw", value: "withdraw" },
                { text: "Task Withdraw", value: "task_withdraw" },
                { text: "Group", value: "user_group" }
            ],
            brokers: [],
            root: 0
        };
    },
    computed: {
        ...mapState(["brokerMap"]),
        filteredDataSource () {
            const conditions = [];
            if (this.loginName) {
                conditions.push(this.filterLoginName);
            }
            if (this.bookName) {
                conditions.push(this.filterBookName);
            }
            if (this.depositName) {
                conditions.push(this.filterDepositName);
            }
            if (this.taskDepositName) {
                conditions.push(this.filterTaskDepositName);
            }
            if (this.withdrawName) {
                conditions.push(this.filterWithdrawName);
            }
            if (this.taskWithdrawName) {
                conditions.push(this.filterTaskWithdrawName);
            }
            if (this.groupName) {
                conditions.push(this.filterGroupName);
            }
            if (conditions.length > 0) {
                return this.tradesList.filter((item) => {
                    return conditions.every((condition) => {
                        return condition(item);
                    });
                });
            }
            return this.tradesList;
        }
    },
    watch: {
        selectedBrokerId (nv, ov) {
            this.filterBookLists = this.getBookListByBrokerId(nv);
            // this.loadBook();
        }
    },
    methods: {
        tableEventSortBy (data) {
            console.log(data);
        },
        tableEventSortDesc (data) {
            console.log(data);
        },
        /**
         * 获取转账记录
         * @return {[type]} [description]
         */
        async fetchTransactionRecord () {
            const self = this;

            if (self.selectedTransactionType.length == 0) {
                self.snackBarDanger(
                    "Please select at least one transaction type"
                );
            }

            const params = {
                action: self.selectedTransactionType,
                broker_id: self.selectedBrokerId,
                book: self.selectedBook,
                offset: self.selectedOffsetStart,
                limit: self.selectedLimit,
                order_by: self.selectedOrderBy,
                start_date: self.selectedStartDate,
                end_date: self.selectedEndDate,
                is_asc: self.isAsc,
                login: self.selectedLogin
            };
            self.btnLoading = true;
            self.tableLoading = true;
            self.tradesList = [];
            apiSearchTransaction.create(params).then((res) => {
                const data = res.data;
                // console.log(data);
                if (data.length == 0) self.snackBarInfo("No data found!");
                self.tradesList = data;
                self.btnLoading = false;
                self.tableLoading = false;
            });
        },
        setFocus (item) {
            setTimeout(() => {
                this.$refs[`${item}`].focus();
            }, 200);
        },
        showMore () {
            this.showMoreStatus = !this.showMoreStatus;
        },
        filterLoginName (item) {
            return item.login.toString().includes(this.loginName);
        },
        filterBookName (item) {
            return item.book
                .toLowerCase()
                .includes(this.bookName.toLowerCase());
        },
        filterGroupName (item) {
            return item.user_group
                .toLowerCase()
                .includes(this.groupName.toLowerCase());
        },
        filterDepositName (item) {
            return item.deposit >= this.depositName;
        },
        filterTaskDepositName (item) {
            return item.task_deposit >= this.taskDepositName;
        },
        filterWithdrawName (item) {
            return item.withdraw <= this.withdrawName;
        },
        filterTaskWithdrawName (item) {
            return item.task_withdraw <= this.taskWithdrawName;
        }
    },
    created () {
        // 获取当前账户对应权限的broker及broker id
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;
    },
    mounted () {
        const currentDate = new Date();
        this.selectedEndDate = new Date();
        const dateOffset = 24 * 60 * 60 * 1000 * 60; // 5 days
        const startDate = new Date();
        startDate.setTime(startDate.getTime() - dateOffset);
        this.selectedStartDate = startDate;
        this.filterBookLists = this.getBookListByBrokerId(
            this.selectedBrokerId
        );
    }
};
</script>
<style lang="">
</style>
